import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "flex-grow" }
const _hoisted_2 = { class: "flex flex-col justify-center items-center lg:px-8 py-10" }
const _hoisted_3 = { class: "mt-6 w-full max-w-3xl py-4" }
const _hoisted_4 = { class: "text-xs py-6 px-8 shadow-lg rounded-lg bg-white" }
const _hoisted_5 = { class: "font-bold text-xl mb-4 text-neutral-dark" }
const _hoisted_6 = { class: "flex items-center gap-4 justify-center mt-4" }
const _hoisted_7 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode($setup["TheStep"], {
        currentStep: $setup.INVITATION_TIER_ONE.DETAILS,
        steps: $setup.invitationProgress
      }, null, 8, ["currentStep", "steps"]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode($setup["Form"], {
          as: "el-form",
          "validation-schema": $setup.schema,
          "initial-values": $setup.formData,
          "label-position": "top",
          onSubmit: $setup.onSubmit
        }, {
          default: _withCtx(({ isSubmitting, setFieldValue, setFieldError }) => [
            _createElementVNode("div", {
              class: _normalizeClass(isSubmitting && 'isSubmitting-rounded')
            }, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("h2", _hoisted_5, _toDisplayString($setup.SYSTEM.supply_chain_partner_company_information), 1),
                _createVNode($setup["Field"], { name: "ContactName" }, {
                  default: _withCtx(({ field, errorMessage }) => [
                    _createVNode(_component_el_form_item, {
                      class: "label-custom",
                      error: errorMessage,
                      label: "Contact Name",
                      required: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, _mergeProps({
                          modelValue: $setup.formData.ContactName,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.formData.ContactName) = $event))
                        }, field, {
                          placeholder: "Enter Name of Contact Person",
                          class: "custom-placeholder h-9",
                          required: ""
                        }), null, 16, ["modelValue"])
                      ]),
                      _: 2
                    }, 1032, ["error"])
                  ]),
                  _: 1
                }),
                _createVNode($setup["Field"], { name: "Email" }, {
                  default: _withCtx(({ field, errorMessage }) => [
                    _createVNode(_component_el_form_item, {
                      class: "label-custom",
                      error: errorMessage,
                      label: "Contact Email",
                      required: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, _mergeProps({
                          modelValue: $setup.formData.Email,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.formData.Email) = $event))
                        }, field, {
                          placeholder: "Enter Email of Contact Person",
                          class: "custom-placeholder h-9",
                          required: ""
                        }), null, 16, ["modelValue"])
                      ]),
                      _: 2
                    }, 1032, ["error"])
                  ]),
                  _: 1
                }),
                _createVNode($setup["Field"], { name: "CompanyName" }, {
                  default: _withCtx(({ field, errorMessage }) => [
                    _createVNode(_component_el_form_item, {
                      class: "label-custom",
                      error: errorMessage,
                      label: "Company Name",
                      required: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, _mergeProps({
                          modelValue: $setup.formData.CompanyName,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.formData.CompanyName) = $event))
                        }, field, {
                          placeholder: "Enter Company Name",
                          class: "custom-placeholder h-9",
                          required: ""
                        }), null, 16, ["modelValue"])
                      ]),
                      _: 2
                    }, 1032, ["error"])
                  ]),
                  _: 1
                }),
                _createVNode($setup["Field"], { name: "CompanyRegistrationNumber" }, {
                  default: _withCtx(({ field, errorMessage }) => [
                    _createVNode(_component_el_form_item, {
                      class: "label-custom",
                      error: errorMessage,
                      label: "Company Registration Number"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, _mergeProps({
                          modelValue: $setup.formData.CompanyRegistrationNumber,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.formData.CompanyRegistrationNumber) = $event))
                        }, field, {
                          placeholder: "Enter Company Registration Number",
                          class: "custom-placeholder h-9"
                        }), null, 16, ["modelValue"])
                      ]),
                      _: 2
                    }, 1032, ["error"])
                  ]),
                  _: 1
                }),
                _createVNode($setup["Field"], { name: "CountryCode" }, {
                  default: _withCtx(({ field, errorMessage }) => [
                    _createVNode(_component_el_form_item, {
                      class: "w-full label-custom",
                      error: errorMessage,
                      label: "Country",
                      required: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_select, _mergeProps({
                          modelValue: $setup.formData.CountryCode,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.formData.CountryCode) = $event))
                        }, field, {
                          class: "h-9 w-full custom-placeholder custom-select",
                          placeholder: "Select Country",
                          filterable: "",
                          onInput: _cache[5] || (_cache[5] = 
                      (value) => $setup.filterCountryOptions(value.target?.value)
                    ),
                          onChange: 
                      (value) =>
                        $setup.handleCountryChange(value, setFieldValue, setFieldError)
                    ,
                          onBlur: (event) => $setup.handleCountryBlur(event, setFieldValue),
                          onFocus: $setup.resetCountryOptions
                        }), {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.sortedOptions(), (item) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                key: item.Value,
                                label: item.Name,
                                value: item.Value
                              }, null, 8, ["label", "value"]))
                            }), 128))
                          ]),
                          _: 2
                        }, 1040, ["modelValue", "onChange", "onBlur"])
                      ]),
                      _: 2
                    }, 1032, ["error"])
                  ]),
                  _: 2
                }, 1024)
              ])
            ], 2),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("button", {
                class: "btn bg-white border border-neutral-black hover:bg-gray-100 px-10 py-2",
                onClick: _withModifiers($setup.handleBack, ["prevent"])
              }, " Back "),
              _createElementVNode("button", {
                type: "submit",
                class: "btn btn--green-primary px-8 py-2 disabled:bg-status-draft disabled:text-white",
                disabled: !$setup.isFormValid
              }, " Submit ", 8, _hoisted_7)
            ])
          ]),
          _: 1
        }, 8, ["validation-schema", "initial-values"])
      ])
    ])
  ]))
}
import { defineComponent as _defineComponent } from 'vue'
import { useRouter } from "vue-router";
import { configure, Field, Form } from "vee-validate";
import TheStep from "@/components/TheStep/TheStep.vue";
import { INVITATION_TIER_ONE, invitationProgress } from "@ems/constants/steps";
import { computed, ComputedRef, onMounted, ref, watch } from "vue";
import { object, string } from "yup";
import { ICountries } from "@/models/Metadata";
import MetadataModule from "@/store/modules/Metadata";
import { ISingleInvitationRequest } from "@/models/SupplyChainManagement";
import { LANGUAGE } from "@ems/constants/language";
import { NUMERIC_VALIDATION } from "@ems/constants/validation_form";
import SupplyChainManamentModule from "@ems/containers/SupplyChainManagement/SupplyChainManament.module";
import { SYSTEM } from "@ems/locales/system";


export default /*@__PURE__*/_defineComponent({
  __name: 'SingleInvitation',
  setup(__props, { expose: __expose }) {
  __expose();

const router = useRouter();

const formData = ref<ISingleInvitationRequest>({
  ContactName: "",
  Email: "",
  CompanyName: "",
  CompanyRegistrationNumber: "",
  CountryCode: "",
  Lang: LANGUAGE.EN,
});

configure({
  validateOnBlur: false,
  validateOnChange: false,
  validateOnInput: false,
  validateOnModelUpdate: true,
});

const schema = object({
  ContactName: string()
    .required()
    .label("Contact Name")
    .max(50, "Contact Name must be at most 50 characters"),
  Email: string()
    .required()
    .email("Contact Email must be a valid email (abc@abc.abc)")
    .label("Contact Email")
    .max(150, "Contact Email must be at most 150 characters"),
  CompanyName: string()
    .required()
    .label("Company Name")
    .max(150, "Company Name must be at most 150 characters"),
  CompanyRegistrationNumber: string()
    .nullable()
    .label("Company Registration Number")
    .max(20, "Company Registration Number must be at most 20 characters"),
  CountryCode: string()
    .test("is-valid-country", "Country is required field", function () {
      return formData.value.CountryCode.length > 0;
    })
    .label("Country"),
});

const optionsCountry = ref<ICountries[]>([]);
const isFormValid = ref(false);

const allCountries: ComputedRef<ICountries[]> = computed(
  () => MetadataModule.dataCountriesNewGetter
);

const handleBack = () => {
  router.push("/brand-owner-information/send-invitation");
};

const onSubmit = async (values: ISingleInvitationRequest) => {
  // Submit Send Invitation
  isFormValid.value = false;
  await SupplyChainManamentModule.invitePartner(values);
  if (SupplyChainManamentModule.hasInvitePartnerErrorMsg) {
    isFormValid.value = true;
  } else {
    router.push("/brand-owner-information");
  }
};

const filterMethod = (query: string) => {
  if (query) {
    setTimeout(() => {
      optionsCountry.value = Object.entries(allCountries.value)
        .filter(([key, item]) => {
          return item.Name.toLowerCase().includes(query.toLowerCase());
        })
        .map(([key, item]) => ({
          Value: item.Value,
          Name: item.Name,
          PhoneCode: item.PhoneCode,
        }));
    }, 200);
  } else {
    optionsCountry.value = Object.entries(allCountries.value).map(
      ([key, item]) => ({
        Value: item.Value,
        Name: item.Name,
        PhoneCode: item.PhoneCode,
      })
    );
  }
};

const resetCountryOptions = () => {
  optionsCountry.value = Object.entries(allCountries.value).map(
    ([key, item]) => ({
      Value: item.Value,
      Name: item.Name,
      PhoneCode: item.PhoneCode,
    })
  );
};

const filterCountryOptions = (query: string) => {
  if (query) {
    setTimeout(() => {
      optionsCountry.value = Object.entries(allCountries.value)
        .filter(([key, item]) => {
          return item.Name.toLowerCase().includes(query.toLowerCase());
        })
        .map(([key, item]) => ({
          Value: item.Value,
          Name: item.Name,
          PhoneCode: item.PhoneCode,
        }));
    }, 200);
  } else {
    resetCountryOptions();
  }
};

const handleCountryChange = async (
  value: string,
  setFieldValue: any,
  setFieldError: any
) => {
  setFieldValue("CountryCode", value);
  filterMethod("");
};

const handleCountryBlur = (event: any, setFieldValue: any) => {
  const value = event.target?.value;
  if (!value || value === "") return;
  const validValue = optionsCountry.value.some((item) => item.Value === value);
  if (!validValue) {
    setFieldValue("CountryCode", "");
  }
};

const sortedOptions = () => {
  return optionsCountry.value
    .slice()
    .sort((a, b) => a.Name.localeCompare(b.Name));
};

const filterOptionCountry = () => {
  optionsCountry.value = Object.entries(allCountries.value).map(
    ([key, item]) => {
      return {
        Value: `${item.Value}`,
        Name: `${item.Name}`,
        PhoneCode: `${item.PhoneCode}`,
      };
    }
  );
};

watch(allCountries, () => {
  filterOptionCountry();
});

watch(
  () => formData.value,
  async (newVal) => {
    let checkValid = false;
    try {
      await schema.validate(newVal, {
        abortEarly: false,
      });
      checkValid = true;
    } catch (error) {
      checkValid = false;
    }
    isFormValid.value = checkValid;
  },
  { deep: true }
);

onMounted(() => {
  MetadataModule.getAllCountryAction();
  filterOptionCountry();
});

const __returned__ = { router, formData, schema, optionsCountry, isFormValid, allCountries, handleBack, onSubmit, filterMethod, resetCountryOptions, filterCountryOptions, handleCountryChange, handleCountryBlur, sortedOptions, filterOptionCountry, get useRouter() { return useRouter }, get configure() { return configure }, get Field() { return Field }, get Form() { return Form }, TheStep, get INVITATION_TIER_ONE() { return INVITATION_TIER_ONE }, get invitationProgress() { return invitationProgress }, computed, ComputedRef, onMounted, ref, watch, get object() { return object }, get string() { return string }, get ICountries() { return ICountries }, get MetadataModule() { return MetadataModule }, get ISingleInvitationRequest() { return ISingleInvitationRequest }, get LANGUAGE() { return LANGUAGE }, get NUMERIC_VALIDATION() { return NUMERIC_VALIDATION }, get SupplyChainManamentModule() { return SupplyChainManamentModule }, get SYSTEM() { return SYSTEM } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})